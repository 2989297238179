import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="backoffice--home"
export default class extends Controller {
  static targets = ["nonEndingSessions"]
  static values = {
    daterange: Number,
  }
  connect() {
    // stats_num_of_sessions_not_ended

    if (this.hasNonEndingSessionsTarget) {
      fetch(`/backoffice/home/num_of_sessions_not_ended?daterange=${this.daterangeValue}`)
        .then((response) => response.json())
        .then((data) => {
          this.nonEndingSessionsTarget.innerHTML = `${data.num_of_sessions_not_ended} / ${data.num_of_sessions}`
        })
    }
  }

  daterange(event) {
    window.location.href = "?daterange=" + event.currentTarget.value
  }
}
