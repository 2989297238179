import Slider from "bootstrap-slider/dist/bootstrap-slider"
import moment from "moment/moment"

$(function () {
  function convertMinutesToTimeString(minutes) {
    return moment.utc(0).minutes(minutes).format("h:mm A")
  }
  $(".js-sleep-slider").slider({
    formatter: function (value) {
      // value can an a single value or an array. We are only concerned with
      // formatting the array version
      if ($.isArray(value)) {
        const lower = convertMinutesToTimeString(value[0])
        const upper = convertMinutesToTimeString(value[1])
        return `Start: ${lower}, End ${upper}`
      } else {
        const val = convertMinutesToTimeString(value)
        return `${val}`
      }
    },
  })

  $("button.slider-copy-previous").click(function (event) {
    $(this)
      .parent()
      .find(".js-sleep-slider")
      .slider("setValue", $(this).parent().prev().find(".js-sleep-slider").slider("getValue"))
    return false
  })

  //country select

  $("#user_country").change(function (e) {
    $("#user_country option:selected").each(function () {
      if ($(this).val() != "US") {
        $("#user_state")
          .prop("disabled", function (i, v) {
            return !v
          })
          .hide()
        $("#user_state option:eq(0)").prop("selected", true)
      } else {
        $("#user_state").prop("disabled", false).show()
      }
    })
  })
})
