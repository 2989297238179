// Class definition
var SSFormControls = (function () {
  // Private functions
  var _initAssessment = function () {
    if (document.getElementById("sleep_assessment_step_1") != null) {
      FormValidation.formValidation(document.getElementById("sleep_assessment_step_1"), {
        fields: {
          "user[first_name]": {
            validators: {
              notEmpty: {
                message: "First name is required",
              },
            },
          },
          "user[last_name]": {
            validators: {
              notEmpty: {
                message: "Last name is required",
              },
            },
          },

          "user[gender]": {
            validators: {
              notEmpty: {
                message: "Please select an option",
              },
            },
          },

          "user[time_zone]": {
            validators: {
              notEmpty: {
                message: "Please select an option",
              },
            },
          },

          "user[birthdate]": {
            validators: {
              notEmpty: {
                message: "Please select your date of birth",
              },
              date: {
                format: "MM/DD/YYYY",
                message: "The value is not a valid date",
                min: "01/01/1900",
                max: "01/01/2021",
              },
            },
          },

          "user[country]": {
            validators: {
              notEmpty: {
                message: "Please select a country",
              },
            },
          },

          "user[state]": {
            validators: {
              callback: {
                message: "Please select a state",
                callback: function (input) {
                  const selectedCountry = $("#user_country option:selected")

                  return selectedCountry.val() !== "US"
                    ? // The field is valid if user picks
                      // a given framework from the list
                      true
                    : // Otherwise, the field value is required
                      input.value !== ""
                },
              },
            },
          },
        },

        plugins: {
          //Learn more: https://formvalidation.io/guide/plugins
          trigger: new FormValidation.plugins.Trigger(),
          // Bootstrap Framework Integration
          bootstrap: new FormValidation.plugins.Bootstrap(),
          // Validate fields when clicking the Submit button
          submitButton: new FormValidation.plugins.SubmitButton(),
          // Submit the form when all fields are valid
          defaultSubmit: new FormValidation.plugins.DefaultSubmit(),
        },
      })
    }
  }

  var _initPersonalInfo = function () {
    if (document.getElementById("personal_info_form") != null) {
      FormValidation.formValidation(document.getElementById("personal_info_form"), {
        fields: {
          "user[first_name]": {
            validators: {
              notEmpty: {
                message: "First name is required",
              },
            },
          },
          "user[last_name]": {
            validators: {
              notEmpty: {
                message: "Last name is required",
              },
            },
          },

          "user[gender]": {
            validators: {
              notEmpty: {
                message: "Please select an option",
              },
            },
          },

          "user[time_zone]": {
            validators: {
              notEmpty: {
                message: "Please select an option",
              },
            },
          },

          "user[birthdate]": {
            validators: {
              notEmpty: {
                message: "Please select your date of birth",
              },
              date: {
                format: "MM/DD/YYYY",
                message: "The value is not a valid date",
                min: "01/01/1900",
                max: "01/01/2021",
              },
            },
          },

          "user[country]": {
            validators: {
              notEmpty: {
                message: "Please select a country",
              },
            },
          },

          "user[state]": {
            validators: {
              callback: {
                message: "Please select a state",
                callback: function (input) {
                  const selectedCountry = $("#user_country option:selected")

                  return selectedCountry.val() !== "US"
                    ? // The field is valid if user picks
                      // a given framework from the list
                      true
                    : // Otherwise, the field value is required
                      input.value !== ""
                },
              },
            },
          },
        },

        plugins: {
          //Learn more: https://formvalidation.io/guide/plugins
          trigger: new FormValidation.plugins.Trigger(),
          // Bootstrap Framework Integration
          bootstrap: new FormValidation.plugins.Bootstrap(),
          // Validate fields when clicking the Submit button
          submitButton: new FormValidation.plugins.SubmitButton(),
          // Submit the form when all fields are valid
          defaultSubmit: new FormValidation.plugins.DefaultSubmit(),
        },
      })
    }
  }

  return {
    // public functions
    init: function () {
      _initAssessment()
      _initPersonalInfo()
    },
  }
})()

jQuery(document).ready(function () {
  SSFormControls.init()
})
