"use strict"
import "bootstrap-daterangepicker/daterangepicker.js"

// Shared Colors Definition

var KTApexChartsDemo = (function () {
  // Private functions

  var _appData = function () {
    const apexChart = "#chart_total_sleep_time"

    $.extend(SurveysOptions1, {
      colors: ["#90C16A", "#90C16A"],
      noData: {
        text: "No Data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: -25,
        style: { color: "red", fontSize: "21px" },
      },
    })

    $.extend(SurveysOptions2, {
      colors: ["#90C16A", "#90C16A"],
      noData: {
        text: "No Data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: -25,
        style: { color: "red", fontSize: "21px" },
      },
    })

    $.extend(SurveysOptions3, {
      colors: ["#90C16A", "#90C16A"],
      noData: {
        text: "No Data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: -25,
        style: { color: "red", fontSize: "21px" },
      },
    })

    $.extend(SurveysOptions4, {
      colors: ["#90C16A", "#90C16A"],
      noData: {
        text: "No Data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: -25,
        style: { color: "red", fontSize: "21px" },
      },
    })

    $.extend(TotalSleepTimeOptionsAppData, {
      colors: ["#05263F", "#05263F", "#7CAEC6", "#7CAEC6"],
      noData: {
        text: "No Data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: -25,
        style: { color: "red", fontSize: "21px" },
      },
    })

    $.extend(SleepEfficiencyOptionsAppData, {
      colors: ["#90C16A", "#90C16A"],
      noData: {
        text: "No Data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: -25,
        style: { color: "red", fontSize: "21px" },
      },
    })

    // $.extend( BedTimeOptionsAppData, { colors: ['#52526C', '#EE905A', '#EE905A', '#7CAEC6', '#05263F', '#05263F'], noData: { text: 'No Data', align: 'center', verticalAlign: 'middle', offsetX: 0, offsetY: -25, style: {color: 'red',fontSize: '21px',}}  })

    // $.extend( SleepQualityOptions, { colors: [ '#52526C', '#B3A0B9', '#05263F', '#7CAEC6', '#EE905A', '#90C16A'], noData: { text: 'No Data', align: 'center', verticalAlign: 'middle', offsetX: 0, offsetY: -25, style: {color: 'red',fontSize: '21px',}}  })
    //
    // $.extend( NumOfAwakeningsOptions,{ colors: ['#EE905A', '#90C16A', '#05263F', '#7CAEC6', '#52526C', '#B3A0B9'], noData: { text: 'No Data', align: 'center', verticalAlign: 'middle', offsetX: 0, offsetY: -25, style: {color: 'red',fontSize: '21px',}}  } )
    //
    $.extend(LatencyOptionsAppData, {
      colors: ["#B3A0B9", "#B3A0B9"],
      noData: {
        text: "No Data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: -25,
        style: { color: "red", fontSize: "21px" },
      },
    })
    $.extend(WasoOptionsAppData, {
      colors: ["#EE905A", "#EE905A"],
      noData: {
        text: "No Data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: -25,
        style: { color: "red", fontSize: "21px" },
      },
    })

    //

    var chart = new ApexCharts(document.querySelector("#chart_total_sleep_time"), TotalSleepTimeOptionsAppData)
    chart.render()

    var chart = new ApexCharts(document.querySelector("#chart_sleep_efficiency"), SleepEfficiencyOptionsAppData)
    chart.render()

    // var chart = new ApexCharts(document.querySelector("#chart_bed_time"), BedTimeOptions);
    // chart.render();
    //
    // var chart = new ApexCharts(document.querySelector("#chart_sleep_quality"), SleepQualityOptions);
    // chart.render();
    //
    // var chart = new ApexCharts(document.querySelector("#chart_num_of_awakenings"), NumOfAwakeningsOptions);
    // chart.render();
    //
    //
    var chart = new ApexCharts(document.querySelector("#chart_sleep_latency"), LatencyOptionsAppData)
    chart.render()
    var chart = new ApexCharts(document.querySelector("#chart_waso"), WasoOptionsAppData)
    chart.render()


    var addSurveyChart = function (id, opts) {
      if (id == null) {
        return;
      }
      console.log('Dash2: Create Survey Widget With ID: ', id);
      try {
        var chart = new ApexCharts(document.querySelector(id), opts)
        chart.render()
      } catch (e) {
        console.warn(e)
      }
    }

    addSurveyChart("#chart_surveys_1", SurveysOptions1);
    addSurveyChart("#chart_surveys_2", SurveysOptions2);
    addSurveyChart("#chart_surveys_3", SurveysOptions3);
    addSurveyChart("#chart_surveys_4", SurveysOptions4);

  }

  return {
    // public functions
    init: function () {
      if (typeof TotalSleepTimeOptionsAppData !== "undefined") {
        _appData()
      }
    },
  }
})()

jQuery(document).ready(function () {
  KTApexChartsDemo.init()
})
