import Rails from "@rails/ujs"
import { Controller } from "@hotwired/stimulus"

const stripe_key = document.querySelector("meta[name='stripe-key']").getAttribute("content")

const stripe = Stripe(stripe_key)
const elements = stripe.elements()

// Connects to data-controller="account"
export default class extends Controller {
  static targets = ["form", "clientSecret", "email", "cardElement", "cardError", "submitButton"]

  connect() {
    // Handle users with existing card who would like to use a new one
    // let newCard = document.querySelector("#use-new-card")
    // if (newCard !== null) {
    //     newCard.addEventListener("click", (event) => {
    //         event.preventDefault()
    //         document.querySelector("#payment-form").classList.remove("d-none")
    //         document.querySelector("#existing-card").classList.add("d-none")
    //     })
    // }

    // bindings of `this` to the functions
    this.card = elements.create("card", {
      classes: {
        base: "form-control",
      },
    })
    this.card.mount(this.cardElementTarget)

    const displayError = this.cardErrorTarget

    this.card.addEventListener("change", (event) => {
      if (event.error) {
        displayError.textContent = event.error.message
      } else {
        displayError.textContent = ""
      }
    })
  }

  handleCheckout(event) {
    const form = this.formTarget
    let setupIntentId = form.dataset.setupIntent
    const displayError = this.cardErrorTarget

    event.preventDefault()
    this.submitButtonTarget.classList.add("spinner", "spinner-white", "spinner-right")
    this.submitButtonTarget.disabled = true

    // let name = form.querySelector("#name_on_card").value
    let data = {
      payment_method_data: {
        card: this.card,
      },
    }

    stripe
      .confirmCardSetup(setupIntentId, {
        payment_method: data.payment_method_data,
      })
      .then((result) => {
        if (result.error) {
          displayError.textContent = result.error.message
          this.submitButtonTarget.classList.remove("spinner", "spinner-white", "spinner-right")
          this.submitButtonTarget.disabled = false
        } else {
          this.addHiddenField(form, "payment_method_token", result.setupIntent.payment_method)
          Rails.fire(form, "submit")
        }
      })
  }

  addHiddenField(form, name, value) {
    let input = document.createElement("input")
    input.setAttribute("type", "hidden")
    input.setAttribute("name", name)
    input.setAttribute("value", value)
    form.appendChild(input)
  }
}
