import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clients--sleep-session"
export default class extends Controller {
  static values = { sleepSessionId: Number, clientId: Number }
  static targets = ["chartHeartRateMean", "chartPhoneMotion", "chartWatchMotion", "chartSoundInRoom"]

  connect() {
    this.heartrate()
    this.phoneMotion()
    this.watchMotion()
    this.ambientDb()
  }

  heartrate() {
    const HeartRateMeanAppData = {
      chart: {
        type: "line",
        height: "300",

        toolbar: {
          show: true,
          tools: {
            download: false,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
        },
      },
      legend: {
        position: "top",
      },
      stroke: {
        width: [1.5],
        dashArray: [0],
      },
      series: [],
      xaxis: {
        type: "datetime",
        labels: {
          format: "h:mm tt",
        },
      },
      tooltip: {
        x: {
          format: "h:mm tt",
        },
      },
      colors: ["#7CAEC6"],
      noData: {
        text: "Loading...",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: -25,
        style: {
          color: "red",
          fontSize: "21px",
        },
      },
    }
    const chart = new ApexCharts(this.chartHeartRateMeanTarget, HeartRateMeanAppData)
    chart.render()
    var url = `/clients/${this.clientIdValue}/sleep_sessions/${this.sleepSessionIdValue}/heartrate`
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        chart.updateSeries([
          {
            name: "Heart Rate",
            data: data.heartrate,
          },
        ])
      })
  }
  phoneMotion() {
    const PhoneMotionAppData = {
      chart: {
        type: "line",
        height: "300",

        toolbar: {
          show: true,
          tools: {
            download: false,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
        },
      },
      legend: {
        position: "top",
      },
      stroke: {
        width: [1.5],
        dashArray: [0],
      },
      series: [],
      xaxis: {
        type: "datetime",
        labels: {
          format: "h:mm tt",
        },
      },
      tooltip: {
        x: {
          format: "h:mm tt",
        },
      },
      colors: ["#EE905A"],
      noData: {
        text: "Loading...",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: -25,
        style: {
          color: "red",
          fontSize: "21px",
        },
      },
    }
    const chart = new ApexCharts(this.chartPhoneMotionTarget, PhoneMotionAppData)
    chart.render()
    var url = `/clients/${this.clientIdValue}/sleep_sessions/${this.sleepSessionIdValue}/phone_motion`
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        chart.updateSeries([
          {
            name: "Phone Motion",
            data: data.phone_motion,
          },
        ])
      })
  }
  watchMotion() {
    const WatchMotionAppData = {
      chart: {
        type: "line",
        height: "300",

        toolbar: {
          show: true,
          tools: {
            download: false,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
        },
      },
      legend: {
        position: "top",
      },
      stroke: {
        width: [1.5],
        dashArray: [0],
      },
      series: [],
      xaxis: {
        type: "datetime",
        labels: {
          format: "h:mm tt",
        },
      },
      tooltip: {
        x: {
          format: "h:mm tt",
        },
      },
      colors: ["#B3A0B9"],
      noData: {
        text: "Loading...",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: -25,
        style: {
          color: "red",
          fontSize: "21px",
        },
      },
    }
    const chart = new ApexCharts(this.chartWatchMotionTarget, WatchMotionAppData)
    chart.render()
    var url = `/clients/${this.clientIdValue}/sleep_sessions/${this.sleepSessionIdValue}/watch_motion`
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        chart.updateSeries([
          {
            name: "Watch Motion",
            data: data.watch_motion,
          },
        ])
      })
  }
  ambientDb() {
    const ambientDbAppData = {
      chart: {
        type: "line",
        height: "300",

        toolbar: {
          show: true,
          tools: {
            download: false,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
        },
      },
      legend: {
        position: "top",
      },
      stroke: {
        width: [1],
        dashArray: [0],
      },
      series: [],
      xaxis: {
        type: "datetime",
        labels: {
          format: "h:mm tt",
        },
      },
      tooltip: {
        x: {
          format: "h:mm tt",
        },
      },
      colors: ["#90C16A"],
      noData: {
        text: "Loading...",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: -25,
        style: {
          color: "red",
          fontSize: "21px",
        },
      },
    }
    const chart = new ApexCharts(this.chartSoundInRoomTarget, ambientDbAppData)
    chart.render()
    var url = `/clients/${this.clientIdValue}/sleep_sessions/${this.sleepSessionIdValue}/ambient_db`
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        chart.updateSeries([
          {
            name: "Sound In Room",
            data: data.ambient_db,
          },
        ])
      })
  }
}
