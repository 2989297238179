"use strict"
import "bootstrap-daterangepicker/daterangepicker.js"

// Shared Colors Definition

var KTApexChartsDemo = (function () {
  // Private functions

  var _initDaterangepicker = function () {
    if ($("#kt_dashboard_daterangepicker").length == 0) {
      return
    }

    var picker = $("#kt_dashboard_daterangepicker")
    var start = moment()
    var end = moment()

    function cb(start, end, label) {
      var title = ""
      var range = ""

      if (end - start < 100 || label == "Today") {
        title = "Today:"
        range = start.format("MMM D")
      } else if (label == "Yesterday") {
        title = "Yesterday:"
        range = start.format("MMM D")
      } else {
        range = start.format("MMM D") + " - " + end.format("MMM D")
      }

      $("#kt_dashboard_daterangepicker_date").html(range)
      $("#kt_dashboard_daterangepicker_title").html(title)

      window.location.search = "start=" + start.format("YYYY-MM-DD") + "&end=" + end.format("YYYY-MM-DD")
    }

    picker.daterangepicker(
      {
        direction: KTUtil.isRTL(),
        startDate: start,
        endDate: end,
        opens: "left",
        applyClass: "btn-primary",
        cancelClass: "btn-light-primary",
        ranges: {
          // 'Today': [moment(), moment()],
          // 'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
          "Prev 7 Days": [moment().subtract(7, "days"), moment().subtract(1, "days")],
          "Prev 30 Days": [moment().subtract(30, "days"), moment().subtract(1, "days")],
          "Prev 90 Days": [moment().subtract(90, "days"), moment().subtract(1, "days")],
          "This Month": [moment().startOf("month"), moment().endOf("month")],
          "Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")],
        },
      },
      cb,
    )

    // cb(start, end, '');
  }

  var _demo1 = function () {
    const apexChart = "#chart_total_sleep_time"

    $.extend(TotalSleepTimeOptions, {
      colors: ["#05263F", "#05263F", "#7CAEC6", "#7CAEC6"],
      noData: {
        text: "No Data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: -25,
        style: { color: "red", fontSize: "21px" },
      },
    })

    $.extend(SleepEfficiencyOptions, {
      colors: ["#90C16A", "#90C16A"],
      noData: {
        text: "No Data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: -25,
        style: { color: "red", fontSize: "21px" },
      },
    })

    $.extend(SurveysOptions1, {
      colors: ["#90C16A", "#90C16A"],
      noData: {
        text: "No Data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: -25,
        style: { color: "red", fontSize: "21px" },
      },
    })

    $.extend(SurveysOptions2, {
      colors: ["#90C16A", "#90C16A"],
      noData: {
        text: "No Data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: -25,
        style: { color: "red", fontSize: "21px" },
      },
    })

    $.extend(SurveysOptions3, {
      colors: ["#90C16A", "#90C16A"],
      noData: {
        text: "No Data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: -25,
        style: { color: "red", fontSize: "21px" },
      },
    })

    $.extend(SurveysOptions4, {
      colors: ["#90C16A", "#90C16A"],
      noData: {
        text: "No Data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: -25,
        style: { color: "red", fontSize: "21px" },
      },
    })

    $.extend(BedTimeOptions, {
      colors: ["#52526C", "#EE905A", "#EE905A", "#7CAEC6", "#05263F", "#05263F"],
      noData: {
        text: "No Data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: -25,
        style: { color: "red", fontSize: "21px" },
      },
    })

    $.extend(SleepQualityOptions, {
      colors: ["#52526C", "#B3A0B9", "#05263F", "#7CAEC6", "#EE905A", "#90C16A"],
      noData: {
        text: "No Data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: -25,
        style: { color: "red", fontSize: "21px" },
      },
    })

    $.extend(NumOfAwakeningsOptions, {
      colors: ["#EE905A", "#90C16A", "#05263F", "#7CAEC6", "#52526C", "#B3A0B9"],
      noData: {
        text: "No Data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: -25,
        style: { color: "red", fontSize: "21px" },
      },
    })

    $.extend(LatencyOptions, {
      colors: ["#B3A0B9", "#B3A0B9"],
      noData: {
        text: "No Data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: -25,
        style: { color: "red", fontSize: "21px" },
      },
    })
    $.extend(WasoOptions, {
      colors: ["#EE905A", "#EE905A"],
      noData: {
        text: "No Data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: -25,
        style: { color: "red", fontSize: "21px" },
      },
    })

    //

    var chart = new ApexCharts(document.querySelector("#chart_total_sleep_time"), TotalSleepTimeOptions)
    chart.render()

    var chart = new ApexCharts(document.querySelector("#chart_sleep_efficiency"), SleepEfficiencyOptions)
    chart.render()

    var addSurveyChart = function (id, opts) {
      if (id == null) {
        return;
      }
      console.log('Dash1: Create Survey Widget With ID: ', id);
      try {
        var chart = new ApexCharts(document.querySelector(id), opts)
        chart.render()
      } catch (e) {
        console.warn(e)
      }
    }

    addSurveyChart("#chart_surveys_1", SurveysOptions1);
    addSurveyChart("#chart_surveys_2", SurveysOptions2);
    addSurveyChart("#chart_surveys_3", SurveysOptions3);
    addSurveyChart("#chart_surveys_4", SurveysOptions4);

    var chart = new ApexCharts(document.querySelector("#chart_bed_time"), BedTimeOptions)
    chart.render()

    var chart = new ApexCharts(document.querySelector("#chart_sleep_quality"), SleepQualityOptions)
    chart.render()

    var chart = new ApexCharts(document.querySelector("#chart_num_of_awakenings"), NumOfAwakeningsOptions)
    chart.render()

    var chart = new ApexCharts(document.querySelector("#chart_sleep_latency"), LatencyOptions)
    chart.render()
    var chart = new ApexCharts(document.querySelector("#chart_waso"), WasoOptions)
    chart.render()
  }

  var _toggledashboardtype = function () {
    $("#dashboard-type").change(function () {
      if (!!this.checked) {
        window.location.href =
          "/clients/" + $(this).data("user-id") + "/dashboard" + "/app_data" + window.location.search
      } else {
        window.location.href =
          "/clients/" + $(this).data("user-id") + "/dashboard" + "/diary_data" + window.location.search
      }
    })

    $("#dashboard-type-user").change(function () {
      if (!!this.checked) {
        window.location.href = "/dashboard/app_data" + window.location.search
      } else {
        window.location.href = "/dashboard/" + window.location.search
      }
    })
  }

  var _toggleexcluded = function () {
    $(".diary-excluded").change(function () {
      var diary_id = $(this).data("diary-id")
      var data = {
        sleep_diary: {
          excluded: !!this.checked,
        },
      }

      $.ajax({
        type: "PUT",
        data: data,
        url: "/api/v3/sleep_diaries/" + diary_id,
        cache: false,
        success: function (data) {
          // change this once we get the dashboard better
          location.reload()
        },
      })
    })
  }

  return {
    // public functions
    init: function () {
      if (typeof TotalSleepTimeOptions !== "undefined") {
        _demo1()
      }
      _initDaterangepicker()
      _toggleexcluded()
      _toggledashboardtype()
    },
  }
})()

jQuery(document).ready(function () {
  KTApexChartsDemo.init()
})
