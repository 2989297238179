$(document).ready(function () {
  $("#sleep_diary_form").submit(function (event) {
    var sleep_time = $("#sleep_time").val()
    var time_of_final_wakeup = $("#time_of_final_wakeup").val()
    var time_out_of_bed = $("#time_out_of_bed").val()
    if (sleep_time < time_out_of_bed && sleep_time < time_of_final_wakeup && time_of_final_wakeup <= time_out_of_bed) {
      return
    } else {
      event.preventDefault()
      if (sleep_time >= time_out_of_bed || sleep_time >= time_of_final_wakeup) {
        $(".sleep_time").addClass("needs-validation")
        $(".sleep_time").find(".invalid-feedback").show()
        $([document.documentElement, document.body]).animate(
          {
            scrollTop: $(".sleep_time").offset().top,
          },
          500,
        )
      }

      if (time_of_final_wakeup > time_out_of_bed) {
        $(".time_of_final_wakeup").addClass("needs-validation")
        $(".time_of_final_wakeup").find(".invalid-feedback").show()
        $([document.documentElement, document.body]).animate(
          {
            scrollTop: $(".time_of_final_wakeup").offset().top,
          },
          500,
        )
      }
    }
  })

  $("#time_of_final_wakeup").on("change", function () {
    time_out_of_bed = $("#time_out_of_bed")
    if (this.value > time_out_of_bed.val()) {
      time_out_of_bed.val(this.value)
    }
  })

  $("#time_out_of_bed").on("change", function () {
    time_of_final_wakeup = $("#time_of_final_wakeup")
    if (this.value < time_of_final_wakeup.val()) {
      time_of_final_wakeup.val(this.value)
    }
  })

  $("#bed_time").on("change", function () {
    sleep_time = $("#sleep_time")
    if (this.value > sleep_time.val()) {
      sleep_time.val(this.value)
    }
  })

  $("#sleep_time").on("change", function () {
    bed_time = $("#bed_time")
    if (this.value < bed_time.val()) {
      bed_time.val(this.value)
    }
  })
})

window.updateTimeToFallAsleepNever = function (v) {
  $("#time-to-fall-asleep").prop("disabled", function (i, v) {
    return !v
  })
}

window.updateTimeToFallAsleep = function (v) {
  $("#time-to-fall-asleep-value").html(convertMintoHours(v.value))
}
window.updateNumOfWakeups = function (v) {
  $("#num-of-wakeups-value").html(v.value)
}

window.updateLengthOfWakeups = function (v) {
  $("#length-of-wakeups-value").html(convertMintoHours(v.value))
}

window.updateQualityOfSleepRating = function (v) {
  $("#quality-of-sleep-rating-value").html(v.value)
}

window.validateForm = function (v) {
  v.preventDefault()

  console.log($("#time_of_final_wakeup"))
  $(".time_of_final_wakeup").addClass("needs-validation")
  $(".time_of_final_wakeup > .invalid-feedback").show()

  // $('div.what-time').addClass('no-valid');
  // $('#invalid-feedback').show();
}

window.toggleInstructions = function () {
  $(".instructions").fadeToggle()
}

function convertMintoHours(minutes) {
  var h = Math.floor(minutes / 60)
  var m = minutes % 60
  h = h < 10 ? "" + h : h
  m = m < 10 ? "0" + m : m
  return h == 0 ? m + " min" : h + " hr " + m + " min"
}

// function convertTime(minutes){
//     var d = new Date();
//     d.setHours(24, 0, 0);
//     d.setMinutes(d.getMinutes() + minutes);
//     return d.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
//
// }
//
// function convertMorningTime(minutes){
//     var d = new Date();
//     d.setHours(0, 0, 0);
//     d.setMinutes(d.getMinutes() + minutes);
//     return d.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
//
// }
