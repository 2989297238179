"use strict"
// Class definition

var KTDatatableHtmlTableDemo = (function () {
  // Private functions

  // demo initializer
  var demo = function () {
    var datatable = $("#client_table").KTDatatable({
      data: {
        saveState: { cookie: false },
      },
      search: {
        input: $("#kt_datatable_search_query"),
        key: "generalSearch",
      },
      layout: {
        class: "datatable-bordered",
      },
      columns: [
        {
          field: "Client",
          title: "#",
          sortable: "asc",
          width: 400,
        },
        {
          field: "Gender",
          title: "#",
          sortable: "asc",
          width: 80,
        },
        {
          field: "DoB",
          title: "#",
          sortable: "asc",
          width: 80,
        },
        {
          field: "Last Diary",
          title: "#",
          sortable: "asc",
          width: 80,
        },
        {
          field: "ACTIONS",
          title: "Actions",
          sortable: false,
          width: 80,
          textAlign: "center",
        },
      ],
    })

    $("#kt_datatable_search_status").on("change", function () {
      datatable.search($(this).val().toLowerCase(), "Status")
    })

    $("#kt_datatable_search_type").on("change", function () {
      datatable.search($(this).val().toLowerCase(), "Type")
    })

    $("#kt_datatable_search_status, #kt_datatable_search_type").selectpicker()
  }

  return {
    // Public functions
    init: function () {
      // init dmeo
      demo()
    },
  }
})()

jQuery(document).ready(function () {
  KTDatatableHtmlTableDemo.init()
})
