// Entry point for the build script in your package.json

require("@rails/ujs").start()
require("@rails/activestorage").start()
import "@hotwired/turbo-rails"
import "./controllers"

// js libraries
import "./src/jquery"
import "./packs/plugins"
import "./src/kt_plugins"
import "chartkick/chart.js"

// Metronic layout extended js

import Bootstrap from "./src/form_validation/FormValidation.Bootstrap"
window.FormValidation.plugins.Bootstrap = Bootstrap

import "./packs/components/datatable/core.datatable.js"
import "./packs/components/datatable/datatable.checkbox.js"
import "./packs/components/datatable/datatable.rtl.js"

import "./packs/sleep-diary"
import "./packs/pages/my-script"
import "./packs/validations/form-controls.js"
import "./packs/pages/dashboard.js"
import "./packs/pages/dashboard_app_data.js"
import "./packs/pages/profile.js"
import "./packs/pages/clients.js"
import "./packs/layout/initialize.js"

import ktappsettings from "./src/kt_app_settings"

window.KTAppSettings = ktappsettings
