// Keenthemes' plugins
import ktutil from "../packs/components/util.js"
window.KTUtil = ktutil

import ktapp from "../packs/components/app.js"
window.KTApp = ktapp

import ktcard from "../packs/components/card.js"
window.KTCard = ktcard

import ktcookie from "../packs/components/cookie.js"
window.KTCookie = ktcookie

import ktdialog from "../packs/components/dialog.js"
window.KTDialog = ktdialog

import ktheader from "../packs/components/header.js"
window.KTHeader = ktheader

import ktimageinput from "../packs/components/image-input.js"
window.KTImageInput = ktimageinput

import ktmenu from "../packs/components/menu.js"
window.KTMenu = ktmenu

import ktoffcanvas from "../packs/components/offcanvas.js"
window.KTOffcanvas = ktoffcanvas

import ktscrolltop from "../packs/components/scrolltop.js"
window.KTScrolltop = ktscrolltop

import kttoggle from "../packs/components/toggle.js"
window.KTToggle = kttoggle

import ktwizard from "../packs/components/wizard.js"
window.KTWizard = ktwizard

// Metronic layout base js
import ktlayoutaside from "../packs/layout/base/aside.js"
window.KTLayoutAside = ktlayoutaside

import KTLayoutAsideMenu from "../packs/layout/base/aside-menu.js"
window.KTLayoutAsideMenu = KTLayoutAsideMenu

import KTLayoutAsideToggle from "../packs/layout/base/aside-toggle.js"
window.KTLayoutAsideToggle = KTLayoutAsideToggle

import KTLayoutBrand from "../packs/layout/base/brand.js"
window.KTLayoutBrand = KTLayoutBrand

import KTLayoutContent from "../packs/layout/base/content.js"
window.KTLayoutContent = KTLayoutContent

import KTLayoutFooter from "../packs/layout/base/footer.js"
window.KTLayoutFooter = KTLayoutFooter

import KTLayoutHeader from "../packs/layout/base/header.js"
window.KTLayoutHeader = KTLayoutHeader

import KTLayoutHeaderMenu from "../packs/layout/base/header-menu.js"
window.KTLayoutHeaderMenu = KTLayoutHeaderMenu

import KTLayoutHeaderTopbar from "../packs/layout/base/header-topbar.js"
window.KTLayoutHeaderTopbar = KTLayoutHeaderTopbar

// window.KTLayoutStickyCard = require('packs/layout/base/sticky-card.js');
// window.KTLayoutStretchedCard = require('packs/layout/base/stretched-card.js');

import KTLayoutSubheader from "../packs/layout/base/subheader.js"
window.KTLayoutSubheader = KTLayoutSubheader
